// utils
import React, { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, getUrlPrefix, LANGUAGES } from "../../../../utils/langUtils";
import { globalLoading }  from "../../../../components/global";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// page sections
import WorkPosition from "./components/work-position/work-position";
import SectionLoadingError from "../../../../components/section-loading-error/section-loading-error";

// files
import loading_animation from "../../../../files/animations/pulsing-stripes-divelit-colors.svg";

// storage for data obtained from backend
let workPositions:any = null;
let workPositionsList:any = [];
let requestErrorCode = "";
let requestErrorText = "";

/**
 * CareerWorkPositions section
 * @returns HTML code for CareerWorkPositions with added work positions (GET from Backend)
 */
export default function CareerWorkPositions() {

    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;
    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }



    const [isLoading, setIsLoading] = useState(false);

    const [isLoaded, setIsLoaded] = useState(false);
    const [requestError, setRequestError] = useState(false);

    // On PAGE load DATA from backend are REQUESTED
    useEffect(() => {
        // Sets GLOBAL LOADING to TRUE
        globalLoading.switch();
        setIsLoading(true);

        workPositionsList = [];
        axios.get("/api/career/getWorkPositionsAll", { params: { lang: language }, timeout: 10000 })
            .then(function (response) {

                workPositions = response.data.data.work_positions;
                
                //console.log(workPositions);
                
                workPositions.map((item:any, i:any) => (
                    workPositionsList.push(item.name)
                    //console.log(item.name)
                ))
                //console.log(workPositionsList);
                // Sets GLOBAL LOADING to FALSE
                globalLoading.switch();
                setIsLoading(false);

                setIsLoaded(true);

                // DEBUG simulates waiting for data - used for debugging
                //window.setTimeout(() => {globalLoading.switch();}, 5000);
            })
            .catch(function (error) {
                // Sets GLOBAL LOADING to FALSE
                globalLoading.switch();
                setIsLoading(false);
                
                // DEBUG whole error message
                //console.log(error);
                
                // error response from backend
                if(error?.code === 'ECONNABORTED') {
                    requestErrorCode = "408";
                    requestErrorText = "Request timeout";
                }
                else {
                    requestErrorCode = "500";
                    requestErrorText = "Internal server error";
                }

                //if(error?.response?.status) {}

                setRequestError(true);
                //console.log(requestError);
            });
    }, [])

    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }
    
    return (
        <>
            <span id="positions"></span>
            <div className="bg-white pt-3 pt-lg-5 pb-3 pb-lg-5">
                <Container className="mt-3 mt-lg-5 mb-3 mb-lg-5">
                    <Row className="">
                        <Col xs={12} sm={12} className="text-center">
                                {/*<h1 className="header-medium header-underline-left-single pb-2">{translation.positions.header}</h1>*/}
                                <h3 className="font-divelit-grey-50 scroll-anim-750 pb-2 mb-3 mb-md-3 mb-lg-5">{translation.positions.header}</h3>
                                {/*<hr className="col-3 col-sm-3 col-md-2 col-lg-2 separator-hr" style={{marginTop: "0.5em"}}/>*/}
                        </Col>
                    </Row>
                    <hr className="sepparator-horiz-divelit-grey-20 sepparator-w-100 scroll-anim-750"/>
                    {isLoading ?
                        <img src={loading_animation}
                            className="form-loading-animation mt-5 ms-auto me-auto w-fit-content d-block h-100" alt={translation.alt.loading_animation}/>
                        :
                        /*<span style={{color: isErrorMsg ? "red" : "green"}}>{formMsg}</span>*/
                        <></>
                    }
                    {/*
                    {isLoaded ? positions.map(item => (
                        <WorkPosition key={item.id} work_position={item} translation={translation}/>
                    ))   :
                        <span>Loading available positions</span>
                        }
                    */}
                    { requestError ? 
                        <>
                            <SectionLoadingError requestErrorCode={requestErrorCode} requestErrorText={requestErrorText} reloadAddress={getUrlPrefix(language) + "career"}></SectionLoadingError>
                        </>
                        : 
                        <></>
                    }
                    {isLoaded ? workPositions.map((item:any, i:any) => (
                        <div key={i}>
                            {/*<span>{item.name}</span>*/}
                            <WorkPosition  work_position={item} id={"id" + i}/>                         
                        </div>
                        )) 
                        : 
                        <></>
                    }
                </Container>
            </div>
        </>
    );
}
